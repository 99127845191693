<template>
  <main>
    <h1>Stwórz swój idealny komputer z nami!</h1>

    <h2>Dlaczego my?</h2>

    <div class="options">
      <div class="option">
        <span class="material-symbols-outlined">local_shipping</span>
        <h3>Ekspresowa dostawa</h3>
        <h5>Gwarantujemy wysyłkę w 48h od złożenia zamówienia</h5>
      </div>
      <div class="option">
        <span class="material-symbols-outlined">support_agent</span>
        <h3>Pomoc techniczna aż przez 2 lata od zakupu</h3>
        <h5>Gwarantujemy 2 lata telefonicznej pomocy technicznej, jak i nie kosztowną naprawę na miejscu</h5>
      </div>
      <div class="option">
        <span class="material-symbols-outlined">settings</span>
        <h3>Personalizacja komputera do twoich potrzeb</h3>
        <h5>Dzięki naszym specjalistom możesz dobrać idealny komputer do twoich potrzeb, nie tracąc ani grosza na zbędne funkcje</h5>
      </div>
    </div>
  </main>
  <div class="configurator">
    <div class="configurator_container">
      <h3>Lista zalecanych konfiguracji</h3>
      <h5>Dzięki naszym specjalistom mamy gotowe konfiguracje komputerów, z których o wiele łatwiej można wybierać</h5>
      <a class="" href="/dla_ciebie">
        <button class="konfigurator">Zobacz dostępne konfiguracje</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>
<style lang="scss" scoped>

main {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1em 2em;

  h1 {
    margin: 2em auto 0;
    max-width: 900px;
    font-size: 60px;
  }

  h2 {
    margin: 2em auto;
    font-size: 32px;
  }

  .options {
    display: flex;
    justify-content: space-around;
    align-items: baseline;

    .option {
      width: 30%;

      padding: 10px 30px;
      border-radius: 12px;

      text-align: left;
      color: var(--white);

      .material-symbols-outlined {
        font-size: 64px;
        margin-top: 0.25em;
      }

      h3 {
        font-size: 26px;
        font-weight: 300;
        margin-top: 0.5em;
        margin-bottom: 0;
        // word-break: break-all;
      }

      h5 {
        font-size: 13px;
        font-weight: 200;
      }

      &:nth-child(1) {
        background-color: rgb(226, 253, 199);
        color: var(--black);
      }
      &:nth-child(2) {
        background-color: var(--black);
      }
      &:nth-child(3) {
        background-color: rgb(84, 84, 210);
      }
    } 
  }
}

.configurator {
  width: 100%;
  background-color: var(--black);

  .configurator_container { 
    max-width: 1000px;
    margin: 0 auto;

    padding: 40px 20px;
    color: var(--white);
    text-align: left;

    h3 {
      font-size: 26px;
      font-weight: 300;
      margin-top: 0.5em;
      margin-bottom: 0;
      // word-break: break-all;
    }

    h5 {
      font-size: 13px;
      font-weight: 200;
    }
  }
}

@media screen and (max-width: 780px) {
  main {
    .options {
      flex-direction: column;

      .option {
        width: 100%;
        margin-bottom: 2em;
      }
    }
  }
}
</style>
