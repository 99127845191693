<template>
    <main>
        <h1>O nas</h1>
        <div class="configurator">
        <div class="configurator_container">
            <h3>
              Od 2021 roku zajmujemy się programowaniem aplikacji, składaniem komputerów jak i konfiguracją oraz instalacją serwerów.<br><br>
              Nasza nazwa, Blueribbon, świadczy o jakości naszej pracy i przykładności do satysfakcji klienta.<br><br>
              Zachęcamy do korzystania z przecen dla nowych klientów!<br><br>
              Do usłyszenia ~ Zespół BlueRibbon
            </h3>
        </div>
        </div>
    </main>
</template>
<script>
export default {
    name: 'HomePage',
}
</script>
<style lang="scss" scoped>
  main {
    h1 {
        max-width: 1000px;
        margin: 2em auto 2em;
        max-width: 900px;
        font-size: 60px;
    }
  
    h2 {
      margin: 2em auto;
      font-size: 32px;
    }
  }
  
  .configurator {
    width: 100%;
    background-color: var(--black);
  
    .configurator_container { 
      max-width: 1000px;
      margin: 0 auto;
  
      padding: 40px 20px;
      color: var(--white);
      text-align: left;
  
      h3 {
        font-size: 22px;
        font-weight: 300;
        margin: 0.5em 0;
        // word-break: break-all;
      }
    }
  }
</style>
  