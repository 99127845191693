<template>
    <main class="configurator">
        <div class="configurator_container">
            <h2>Złóż swój idealny komputer</h2>
            <h3 class="information_header">Ogólne informacje o wybranym systemie:</h3>
            <div class="information">
                <el-collapse>
                    <el-collapse-item title="Moc Komputera">
                        <div class="information_item">Minimalna moc: {{ wattage }}W</div>
                        <div class="information_item">Minimalna Rekomendowana moc: {{ Math.floor(reccomended_wattage) }}W</div>
                        <div class="information_item">Średnie zużycie na godzinę: {{ Math.floor(0.4 * reccomended_wattage) }}Wh</div>
                    </el-collapse-item>
                    <el-collapse-item title="Zużycie komputera">
                        <div class="information_item">Wybierz zużycie w dniu: <el-input-number v-model="hours" :min="1" :max="24" /> </div>
                        <div class="information_item">Średnie zużycie na {{ hours }} godzin: {{ Math.floor(hours * 0.4 * reccomended_wattage) }}Wh</div>
                        <div class="information_item">Średnie zużycie na tydzień ({{ hours }}h w dniu): {{ Math.round(7 * hours * 0.4 * reccomended_wattage / 100) / 10 }}kWh</div>
                        <div class="information_item">Średnie zużycie w miesiącu ({{ hours }}h w dniu): {{ Math.round(30 * hours * 0.4 * reccomended_wattage / 100) / 10 }}kWh</div>
                    </el-collapse-item>
                </el-collapse>
            </div>

            <div class="warnings">
                <div v-if="overwattage" class="warning">
                    <span class="material-symbols-outlined">
                    warning
                    </span>
                    <div>Moc zasilacza jest o wiele większa niż rekomendowana ilość dla tego systemu,<br>rekomendujemy zmiejszyć moc zasilacza albo zmienić wybrane części.</div>
                </div>
                <div v-if="reccomended_gpu" class="error">
                    <span class="material-symbols-outlined">
                    warning
                    </span>
                    <div>Procesor nie zawiera zintegrowanej karty graficznej, aby komputer był funkcjonalny, jest wymagane <br> dodanie karty graficznej albo wybranie procesora z zintegrowaną kartą graficzną</div>
                </div>
                <div v-if="underwattage" class="error">
                    <span class="material-symbols-outlined">
                    warning
                    </span>
                    <div>Moc zasilacza jest o mniejsza niż minimalna ilość wymaagna dla tego systemu,<br>rekomendujemy zwiększyć moc zasilacza albo zmienić wybrane części.</div>
                </div>
                <div v-if="wrong_ram" class="error">
                    <span class="material-symbols-outlined">
                    warning
                    </span>
                    <div>Pamięć RAM nie jest kompatybilna z wybraną płytą główną</div>
                </div>
                <div v-if="bad_cpu" class="error">
                    <span class="material-symbols-outlined">
                    warning
                    </span>
                    <div>Wybrany Procesor nie jest kompatybilny z wybraną płytą główną</div>
                </div>
            </div>

            <div class="selections">
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        memory
                        </span>
                    </div>
                    <div>
                        <h3>Procesor</h3>
                        <el-cascader placeholder="Wyszukaj swój procesor" v-model="procesor" separator=" | " type="primary" :filterable="true" :options="procesors" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        desktop_windows
                        </span>
                    </div>
                    <div>
                        <h3>Płyta Główna</h3>
                        <el-cascader placeholder="Wyszukaj swoją płyte główną" v-model="motherboard" separator=" | " type="primary" :filterable="true" :options="motherboards" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        memory_alt
                        </span>
                    </div>
                    <div>
                        <h3>Pamięć</h3>
                        <el-cascader placeholder="Wyszukaj swój ram" v-model="ram" separator=" | " type="primary" :filterable="true" :options="rams" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        settings_input_hdmi
                        </span>
                    </div>
                    <div>
                        <h3>Grafika</h3>
                        <el-cascader placeholder="Wyszukaj swoją kartę graficzną" v-model="gpu" separator=" | " type="primary" :filterable="true" :options="gpus" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        storage
                        </span>
                    </div>
                    <div>
                        <h3>Dyski</h3>
                        <el-cascader placeholder="Wyszukaj swój drives" v-model="drive" separator=" | " type="primary" :filterable="true" :options="drives" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        mode_fan
                        </span>
                    </div>
                    <div>
                        <h3>Chłodzenie</h3>
                        <el-cascader placeholder="Wyszukaj swoje chłodzenie" v-model="cpucooling" separator=" | " type="primary" :filterable="true" :options="cpucoolings" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        bolt
                        </span>
                    </div>
                    <div>
                        <h3>Zasilacz</h3>
                        <el-cascader placeholder="Wyszukaj swój zasilacz" v-model="psu" separator=" | " type="primary" :filterable="true" :options="psus" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        list_alt
                        </span>
                    </div>
                    <div>
                        <h3>Obudowa</h3>
                        <el-cascader placeholder="Wyszukaj swoją obudowe" v-model="case_item" separator=" | " type="primary" :filterable="true" :options="cases" clearable />
                    </div>
                </div>
                <div class="selection">
                    <div>
                        <span class="material-symbols-outlined">
                        disc_full
                        </span>
                    </div>
                    <div>
                        <h3>System</h3>
                        <el-cascader placeholder="Wyszukaj swój system operacyjny" v-model="system" separator=" | " type="primary" :filterable="true" :options="systems" clearable />
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button class="konfigurator" @click="saveConfiguration">Zapisz</button>
                <button disabled class="konfigurator">Zamów</button>
            </div>
            <div class="buttons">
                {{ version }}
            </div>
        </div>
    </main>
</template>
    
<script>
// v{major}.{minor}{letter - hotfix}
const VERSION = "v0.1c"

import { ElNotification } from 'element-plus'

const base_url = process.env.NODE_ENV === 'development' ? "http://localhost:10000" : "https://blueribbon.pro";

export default {
    name: 'PCConfiguratorPage',
    props: ['configuration_uuid'],
    mounted() {
        this.reevaluateWattage();

        this.axios.get(base_url + "/components/items").then((response) => {
            this.data = response.data;

            this.raw_procesors = this.data["processors"];
            this.procesors = this.process3TabRequest(this.raw_procesors)

            this.raw_motherboards = this.data["motherboards"];
            this.motherboards = this.process1TabRequest(this.raw_motherboards)
            
            this.raw_rams = this.data["rams"];
            this.rams = this.process2TabRequest(this.raw_rams)
            
            this.raw_psus = this.data["psus"];
            this.psus = this.process1TabRequest(this.raw_psus)
            
            this.raw_gpus = this.data["gpus"];
            this.gpus = this.process2TabRequest(this.raw_gpus)
            
            this.raw_cpucoolings = this.data["cpucoolings"];
            this.cpucoolings = this.process1TabRequest(this.raw_cpucoolings)

            this.raw_drives = this.data["drives"];
            this.drives = this.process1TabRequest(this.raw_drives)
            
            this.raw_systems = this.data["systems"];
            this.systems = this.process1TabRequest(this.raw_systems)

            this.raw_case_items = this.data["cases"];
            this.cases = this.process1TabRequest(this.raw_case_items);
        })
    },
    data: () => {
        return {
            data: null,

            procesor: null,
            motherboard: null,
            ram: null,
            psu: null,
            gpu: null,
            cpucooling: null,
            drive: null,
            system: null,
            case_item: null,

            procesors: [],
            motherboards: [],
            rams: [],
            psus: [],
            gpus: [],
            cpucoolings: [],
            drives: [],
            systems: [],
            cases: [],

            raw_procesors: null,
            raw_motherboards: null,
            raw_rams: null,
            raw_psus: null,
            raw_gpus: null,
            raw_cpucoolings: null,
            raw_drives: null,
            raw_systems: null,
            raw_case_items: null,

            hours: 8,

            wattage: 0,
            reccomended_wattage: 0,

            overwattage: false,
            underwattage: false,
            reccomended_gpu: false,
            wrong_ram: false,
            bad_cpu: false,
        }
    },
    watch: {
        procesor: function() {
            this.reValidate();
            this.validateCPU();
        },
        psu: function()  {
            this.reValidate();
        },
        gpu: function()  {
            this.reValidate();
        },
        ram: function() {
            this.validateRAM();
        },
        motherboard: function() {
            this.validateRAM();
            this.validateCPU();
        }
    },
    computed: {
        version() {
            return VERSION;
        },
    },
    methods: {
        loadConfiguration() {
            this.axios.get(base_url + "/components/save_configuration", { params: { uuid: this.configuration_uuid } })
            .then((response) => {
                if (response.data["cpu"] != null) {
                    const component = this.raw_procesors.filter(function(item) {
                        if (item.id == response.data["cpu"]) return item;
                    })[0]
                    
                    this.procesor = [component.tab1, component.tab2, component.id]
                }

                if (response.data["motherboard"] != null) {
                    const component = this.raw_motherboards.filter(function(item) {
                        if (item.id == response.data["motherboard"]) return item;
                    })[0]
                    
                    this.motherboard = [component.id]
                }

                if (response.data["ram"] != null) {
                    const component = this.raw_rams.filter(function(item) {
                        if (item.id == response.data["ram"]) return item;
                    })[0]
                    
                    this.ram = [component.tab1, component.id]
                }
                
                if (response.data["psu"] != null) {
                    const component = this.raw_psus.filter(function(item) {
                        if (item.id == response.data["psu"]) return item;
                    })[0]
                    
                    this.psu = [component.id]
                }
                
                if (response.data["gpu"] != null) {
                    const component = this.raw_gpus.filter(function(item) {
                        if (item.id == response.data["gpu"]) return item;
                    })[0]
                    
                    this.gpu = [component.tab1, component.id]
                }
                
                if (response.data["cpucooling"] != null) {
                    const component = this.raw_cpucoolings.filter(function(item) {
                        if (item.id == response.data["cpucooling"]) return item;
                    })[0]
                    
                    this.cpucooling = [component.id]
                }
                
                if (response.data["drive"] != null) {
                    const component = this.raw_drives.filter(function(item) {
                        if (item.id == response.data["drive"]) return item;
                    })[0]
                    
                    this.drive = [component.id]
                }
                
                if (response.data["system"] != null) {
                    const component = this.raw_systems.filter(function(item) {
                        if (item.id == response.data["system"]) return item;
                    })[0]
                    
                    this.system = [component.id]
                }
                
                if (response.data["case"] != null) {
                    const component = this.raw_case_items.filter(function(item) {
                        if (item.id == response.data["case"]) return item;
                    })[0]
                    
                    this.case_item = [component.id]
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        reValidate() {
            this.reevaluateWattage();
            this.validateCPU_GPU();
        },
        validateRAM() {
            this.wrong_ram = false;

            const p_motherboard = this.motherboard;
            const p_ram = this.ram;
            if (!p_ram || !p_motherboard) {
                return;
            }

            const motherboard = this.raw_motherboards.filter(function(item) {
                if (item.id == p_motherboard[0]) return item;
            })[0]

            const ram = this.raw_rams.filter(function(item) {
                if (item.id == p_ram[1]) return item;
            })[0]

            this.wrong_ram = ram.ram_socket != motherboard.ram_socket
        },
        reevaluateWattage() {
            let total_required_wattage = 0;

            this.overwattage = false;
            this.underwattage = false;

            const cpu = this.procesor;
            const gpu = this.gpu;
            const psu = this.psu;

            if (cpu) {
                total_required_wattage += this.raw_procesors.filter(function(item) {
                    if (item.id == cpu[2]) return item;
                })[0].wattage;
            }

            if (gpu) {
                total_required_wattage += this.raw_gpus.filter(function(item) {
                    if (item.id == gpu[1]) return item;
                })[0].wattage;
            }

            const additional_wattage = 60 + 15 + 15 + 40; // motherboard + memory + cooling + 4 drives
            this.wattage = total_required_wattage + additional_wattage;
            this.reccomended_wattage = 1.4 * total_required_wattage + additional_wattage; // 70% of max 

            // PSU
            if (psu) {
                let selected_psu = this.raw_psus.filter(function(item) {
                    if (item.id == psu[0]) return item;
                })[0];

                const minimal_wattage = this.reccomended_wattage * 1.2; //  minimally 60%
                if (cpu && selected_psu.wattage > minimal_wattage && minimal_wattage > 300) {
                    this.overwattage = true;
                }
                else if (cpu && selected_psu.wattage < this.wattage) {
                    this.underwattage = true;
                }
            }
        },
        validateCPU_GPU() {
            this.reccomended_gpu = false;

            const cpu = this.procesor;
            const gpu = this.gpu;
            
            if (cpu && !gpu) {
                return;
            }

            this.reccomended_gpu = !this.raw_procesors.filter(function(item) {
                if (item.id == cpu[2]) return item;
            })[0].has_igpu;
        },
        validateCPU() {
            this.bad_cpu = false;

            const p_procesor = this.procesor;
            const p_motherboard = this.motherboard;

            if (!p_procesor || !p_motherboard) {
                return;
            }

            const cpu = this.raw_procesors.filter(function(item) {
                if (item.id == p_procesor[2]) return item;
            })[0]
            const motherboard = this.raw_motherboards.filter(function(item) {
                if (item.id == p_motherboard[0]) return item;
            })[0]

            this.bad_cpu = cpu.socket != motherboard.socket;
        },
        async saveConfiguration() {
            const items = {
                cpu: this.procesor,
                motherboard: this.motherboard,
                ram: this.ram,
                psu: this.psu,
                gpu: this.gpu,
                cpucooling: this.cpucooling,
                drive: this.drive,
                system: this.system,
                case: this.case_item,
            }

            let shown_notification = false

            let post_data = {}

            for (const [key, item] of Object.entries(items)) {
                if (item == null) {
                    if (!shown_notification) {
                        ElNotification({
                            title: '',
                            message: "Nie wszystkie wymagane elementy są wybrane",
                            
                            type: 'warning',
                            position: 'bottom-right',
                        })
                        shown_notification = true;
                    }
                } else {
                    post_data[key] = item.slice(-1)[0]
                }
            }

            if (Object.keys(post_data).length == 0) {
                this.$nextTick(() => {
                    ElNotification({
                        title: '',
                        message: "Błąd zapisu, brak wyborów",
                        
                        type: 'error',
                        position: 'bottom-right',
                    })
                })
                return
            } 
            
            try {
                const response = await this.axios.post(base_url + "/components/save_configuration", post_data)
                    
                history.pushState(
                    {},
                    null,
                    response.data["url"]
                )

                ElNotification({
                    title: 'Link do konfiguracji',
                    message: "Skopiowano do schowka",

                    type: 'success',
                    position: 'bottom-right',
                })

                this.$nextTick(() => {
                    ElNotification({
                        title: 'Link do konfiguracji',
                        message: response.data["url"],

                        type: 'success',
                        position: 'bottom-right',
                        duration: 300000,
                    })
                })
            } catch {
                ElNotification({
                    title: '',
                    message: "Nie udało się zapisać twojej konfiguracji, prosimy spróbować za moment",
                    
                    type: 'error',
                    position: 'bottom-right',
                })
            }
        },
        process3TabRequest(requestData) {
            let tab1s = [];
            let tab2s = {} // { Company: [gen1, gen2, ...], ... }

            let total = [];

            for (const component of requestData) {
                let tab1 = component["tab1"];

                if (!tab1s.includes(tab1)) {
                    tab1s.push(tab1)
                    
                    tab2s[tab1] = []

                    total.push({
                        value: tab1,
                        label: tab1,
                        children: [],
                    })
                }
            }

            for (const component of requestData) {
                let tab1 = component["tab1"];
                let tab2 = component["tab2"];

                if (!tab2s[tab1].includes(tab2)) {
                    tab2s[tab1].push(tab2)

                    let tab1s_count = tab1s.indexOf(tab1);

                    total[tab1s_count]["children"].push({
                        value: tab2,
                        label: tab2,
                        children: [],
                    })
                }
            }

            for (const component of requestData) {
                let tab1 = component["tab1"];
                let tab2 = component["tab2"];
                let tab3 = component["tab3"];

                let tab1s_count = tab1s.indexOf(tab1);
                let tab2s_count = tab2s[tab1].indexOf(tab2);

                total[tab1s_count]["children"][tab2s_count]["children"].push({
                    value: component.id,
                    label: tab3 + ' (' + component["price"] + "zł)",
                })
            }

            return total
        },
        // CC -> Company - Component
        process2TabRequest(requestData) {
            let tab1s = [];

            let total = [];

            for (const component of requestData) {
                let tab1 = component["tab1"];

                if (!tab1s.includes(tab1)) {
                    tab1s.push(tab1)

                    total.push({
                        value: tab1,
                        label: tab1,
                        children: [],
                    })
                }
            }

            for (const component of requestData) {
                let tab1 = component["tab1"];
                let tab2 = component["tab2"];

                let tab1s_count = tab1s.indexOf(tab1);

                total[tab1s_count]["children"].push({
                    value: component.id,
                    label: tab2 + ' (' + component["price"] + "zł)",
                })
            }

            return total
        },
        process1TabRequest(requestData) {
            let total = [];

            for (const component of requestData) {
                let tab1 = component["tab1"];

                total.push({
                    value: component.id,
                    label: tab1 + ' (' + component["price"] + "zł)",
                })
            }

            return total
        },
    }, 
}
</script>
<style lang="scss" scoped>
.configurator {
    margin-top: 4em;
    width: 100%;
    background-color: var(--black);

    .configurator_container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        padding: 40px 20px;
        color: var(--white);
        text-align: left;

        .buttons {
            margin-top: 1em;
            display: flex;
            justify-content: flex-end;
            
            button {
                margin-left: 1em;
            }
        }

        .selections {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .selection {
                width: 400px;
                display: flex;
                margin: 2em 0;
                align-items: center;

                .material-symbols-outlined {
                    font-size: 48px;
                    margin-right: 0.25em;
                    margin-top: 0.375em;
                }

                div:last-child {
                    width: calc(100% - 60px)
                }
            }
                
        }


        h2 {
            font-size: 48px; 
            font-weight: 400;
            margin-top: 0em;
            margin-bottom: 0;
            text-align: center;
        }

        h3 {
            font-size: 26px;
            font-weight: 300;
            margin: 0.5em 0;
            // word-break: break-all;
        }

        h5 {
            font-size: 13px;
            font-weight: 200;
        }

        .error, .warning {
            margin: 1em 0;
            min-width: 80%;
            min-height: 60px;
            padding: 20px;
            text-align: center;

            border-radius: 4px;

            box-shadow: 0px 0px 4px #fff1;

            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            font-size: 15px;
            font-weight: 500;

            * {
                margin: 0 0.25em;
            }
        }

        .error {
            background-color: #8B0000;
        }

        .warning {
            color: rgb(96, 98, 102);
            background-color: #F6BE00;
        }

        .information_header {
            margin: 2em 0 1em;
        }

        .information {
            .information_item {
                margin-left: 2em;
                margin-bottom: 1em;
                font-size: 14px;

                &:first-child {
                    margin-top: 1em;
                }
            }
        }
    }
}

:deep(.el-cascader) {
    width: 100%;
    max-width: 450px;
}

:deep(.el-collapse *) {
    background-color: var(--black);
    color: var(--white);
    font-size: 14px;
}

.el-collapse {
    margin-bottom: 1em;
}

</style>
    