<template>
  <MainHeader />
  <router-view></router-view>
</template>

<script>
import MainHeader from './components/MainHeader.vue'

export default {
  name: 'App',
  components: {
    MainHeader,
  }
}
</script>

<style lang="scss">
:root {
  --white: rgb(254, 244, 238);
  --black: rgb(11, 48, 59);
}

* {
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 2em 0 0;
  min-height: 100vh;
  width: 100%;

  background-image: linear-gradient(127deg, rgb(142, 132, 245) 0%, rgb(197, 180, 217) 30%, rgb(252, 217, 210) 65%, rgb(142, 132, 245) 100%)
}

  
button.konfigurator {
  all: unset;

  font-size: 16px;
  font-weight: 200;
  line-height: 30px;

  padding: 10px 20px;
  border-radius: 20px;

  margin: 1em 0;

  color: var(--black);
  background-color: var(--white);
  cursor: pointer;
  
  transition: color 0.3s, background-color 0.3s;
  
  &:hover {
    background-color: color-mix(in srgb, var(--black) 25%, var(--white));
  }

  &.dark {
    color: var(--white);
    background-color: var(--black);
    
    &:hover {
      background-color: color-mix(in srgb, var(--black) 75%, var(--white));
    }
  }

  &:disabled {
    filter: brightness(50%);
    cursor: default;

    &:hover {
      background-color: var(--white);
    }

    &.dark {
      &:hover {
        background-color: var(--black);
      }
    }
  }
}


a {
  all: unset;
}
</style>
