<template>
    <main>
        <h1>Oferty dla Firm!</h1>

        <div class="options">
            <div class="option">
                <h3>Komputery Biurowe / Stacje Robocze</h3>
                <h4>Budżet do 3000zł / od 5000zł</h4>
                <h5>
                    Komputer idealnie stworzony do:
                    <ul>
                        <li>Nauki</li>
                        <li>Pracy Biurowej</li>
                    </ul>
                    Dostępny w formacie mATX, zajmujące najmniej miejsca
                </h5>
                <a href="/konfigurator_pc"><button class="konfigurator dark">Konfigurator PC</button></a>
            </div>
            <div class="option">
                <h3>Strony internetowe</h3>
                <h4>Budżet od 1000zł</h4>
                <h5>
                    W zależności od potrzeb:
                    <ul>
                        <li>Strona Internetowa z szablonu</li>
                        <li>Strona Internetowa na zamówienie</li>
                        <li>Strona Internetowa Wordpress</li>
                    </ul>
                </h5>
                <a href="tel:"><button class="konfigurator">Kontakt</button></a>
            </div>
            <div class="option">
                <h3>Serwery Tower / Rack</h3>
                <h4>Budżet od 5000zł</h4>
                <h5>
                    Wraz z naszymi specjalistami wybierzesz komputer najlepiej dostosowany do twoich potrzeb
                </h5>
                <a href="tel:"><button class="konfigurator">Kontakt</button></a>
            </div>
            <!-- <div class="option">
                <h3>Web / DNS / Mail Hosting</h3>
                <h4>Budżet od 50zł</h4>
                <h5>
                    W zależności od potrzeb hostowanie:
                    <ul>
                        <li>Bazy danych do strony</li>
                        <li>Strony Internetowa</li>
                        <li>Serwera Mailowego</li>
                        <li>Serwera DNS</li>
                    </ul>
                </h5>
                <button class="konfigurator dark">Konfigurator Hostingu</button>
            </div> -->
        </div>
    </main>
</template>
  
<script>
export default {
    name: 'ForComPage',
}
</script>
<style lang="scss" scoped>
main {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1em 2em;

    h1 {
        margin: 2em auto;
        max-width: 900px;
        font-size: 60px;
    }

    .options {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        flex-wrap: wrap;

        .option {
            width: 45%;

            margin-bottom: 2em;
            padding: 10px 30px;
            border-radius: 12px;

            text-align: left;
            color: var(--white);

            .material-symbols-outlined {
                font-size: 64px;
                margin-top: 0.25em;
            }

            h3 {
                font-size: 24px;
                font-weight: 300;
                margin-top: 0.5em;
                margin-bottom: 0;
                // word-break: break-all;
            }

            h4 {
                font-size: 18px;
                font-weight: 300;
                margin-top: 0.5em;
                margin-bottom: 0;
            }

            h5 {
                font-size: 14px;
                font-weight: 200;
            }

            &:nth-child(1) {
                background-color: rgb(226, 253, 199);
                color: var(--black);
            }

            &:nth-child(4) {
                background-color: rgb(255, 153, 102);
                color: var(--black);
            }

            &:nth-child(2) {
                background-color: var(--black);
            }

            &:nth-child(3) {
                background-color: rgb(84, 84, 210);
            }
        }
    }
}

.configurator {
    width: 100%;
    background-color: var(--black);

    .configurator_container {
        max-width: 1000px;
        margin: 0 auto;

        padding: 40px 20px;
        color: var(--white);
        text-align: left;

        h3 {
            font-size: 26px;
            font-weight: 300;
            margin-top: 0.5em;
            margin-bottom: 0;
            // word-break: break-all;
        }

        h5 {
            font-size: 13px;
            font-weight: 200;
        }
    }
}

@media screen and (max-width: 780px) {
    main {
        .options {
            flex-direction: column;

            .option {
                width: 100%;
                margin-bottom: 2em;
            }
        }
    }
}
</style>
  