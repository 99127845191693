import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueAxios from 'vue-axios'
import axios from 'axios'

import AboutPage from "./components/AboutPage.vue"
import HomePage from "./components/HomePage.vue"
import ForYouPage from "./components/ForYouPage.vue"
import ForComPage from "./components/ForComPage.vue"
import PCConfiguratorPage from "./components/PCConfiguratorPage.vue"

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/o_nas', component: AboutPage },
        { path: '/dla_ciebie', component: ForYouPage }, 
        { path: '/dla_firm', component: ForComPage }, 
        { path: '/konfigurator_pc/:configuration_uuid?', component: PCConfiguratorPage, props: true }, 
    ]
});


const app = createApp(App)

app.use(ElementPlus)
app.use(VueAxios, axios)

app.use(router);

app.mount('#app')
