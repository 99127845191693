<template>
    <div class="header">
        <div class="header_nav">
            <a href="/" class="title">
                BlueRibbon
            </a>
            <nav>
                <a href="/o_nas">O&nbsp;nas</a>
                <a href="/dla_ciebie">Dla&nbsp;Ciebie</a>
                <a href="/dla_firm">Dla&nbsp;Firm</a>
            </nav>
        </div>
        <a href="/konfigurator_pc">
            <button class="konfigurator dark">
                Konfigurator
            </button>
        </a>
    </div>
</template>
<script>
export default {
    name: 'MainHeader',
    components: {
    },
    computed: {
        screenWidth() {
            return this.$vssWidth;
        }
    }
}
</script>
<style lang="scss" scoped>
.header {
    margin: 0 auto;
    padding: 20px 25px;
    border-radius: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 1000px;

    background-color: var(--white);

    .header_nav {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
    }

    .title {
        all: unset;
        
        cursor: pointer;
        font-weight: 700;
        color: var(--black);
        font-size: 28px;
        line-height: 30px;

        
        &:hover {
            transition: all 0.3s;
            opacity: 0.6;
        }
    }

    nav {
        a {
            all: unset;

            cursor: pointer;

            font-size: 13px;
            font-weight: 200;
            line-height: 30px;
            color: rgba(var(--black), 0.7);
            margin-left: 1em;

            word-break: keep-all;

            &:hover {
                transition: all 0.3s;
                opacity: 0.6;
            }
        }
    }
}

@media screen and (max-width: calc(1000px + 2em)) {
    .header {
        margin: 0 1em;
    }
}

a {
    all: unset;
}

@media screen and (max-width: 450px) {
    .header {
        justify-content: center;
        button {
            display: none;
        }
    }
}
</style>